/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { selectOptions1 } from './Games/selectOptions1';
import styles from './selectOptions.module.scss';
import { useParams } from 'react-router-dom';
import { getApiClient } from 'src/services/axios';
import GenericModal from 'src/components/Generics/Modal/Modal';
import showToast from 'src/components/utils/toast';
import ModalContentSelectOptions from './ModalContent'
import { selectOptions2 } from './Games/selectOptions2'
import { selectOptions3 } from './Games/selectOptions3'
import { selectOptions4 } from './Games/selectOptions4'
import { selectOptions5 } from './Games/selectOptions5'
import { selectOptions6 } from './Games/selectOptions6';

const selectOptionsList = {
	7: selectOptions1,
	14: selectOptions2,
	21: selectOptions3,
	28: selectOptions4,
	35: selectOptions5,
	42: selectOptions6
};

export default function SelectOptions({ missionId, setPage }) {
	const selectOptions = selectOptionsList[missionId]

	const [userOptions, setUserOptions] = useState([]);
	const [btnText, setBtnText] = useState('Avançar');

	const { playerId } = useParams();
	const [isOpenGenericModal, setIsOpenGenericModal] = useState(false);
	const [showWarning, setShowWarning] = useState(false);

	/// page
	const [currentPage, setCurrentPage] = useState(0);
	const optionsPerPage = 6;
	const startIndex = currentPage * optionsPerPage;
	const endIndex = startIndex + optionsPerPage;
	const currentOptions = selectOptions.options.slice(startIndex, endIndex);

	const handleCheckboxChange = (option) => {
		setUserOptions((prevUserOptions) => {
			if (prevUserOptions.includes(option)) {
				return prevUserOptions.filter((item) => item !== option);
			} else if (prevUserOptions.length < 6) {
				return [...prevUserOptions, option];
			} else {
				setShowWarning(true);
				return prevUserOptions;
			}
		});
	};

	useEffect(() => {
		if (endIndex >= selectOptions.options.length) {
			setBtnText('Enviar');
		} else {
			setBtnText('Avançar');
		}
	}, [currentPage]);

	useEffect(() => {
		if (showWarning) {
			showToast({
				text: 'Só é possível selecionar até 6 opções',
				type: 'error',
			});
			setShowWarning(false);
		}
	}, [showWarning]);

	function handleNextPageModal() {
		const redirect = selectOptions.redirectTo;
		setPage(redirect.type, redirect.missionId);
	}

	const handleNextPage = async () => {
		if (endIndex >= selectOptions.options.length) {
			if(userOptions.length === 0){
				showToast({
					type: 'error',
					text: 'Você deve selecionar pelo menos uma opção'
				})
				return
			}
			try {
				const token = localStorage.getItem('SUT');

				let score = 0;
				for (const option of userOptions) {
					score = score + option.score;
				}
				await getApiClient(token).post(`/updateReport/${playerId}`, {
					missions: [missionId],
					choices: [],
					selectOptionsChoices: [
						{
							missionId: missionId,
							choices: userOptions,
							score: score,
						},
					],
				});
				setIsOpenGenericModal(true);
			} catch (error) {
				showToast({
					text: 'Ocorreu um erro ao tentar salvar a questão, favor reportar.',
					type: 'error',
				});
			}
		} else {
			setCurrentPage(currentPage + 1);
		}
	};
	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<div className={styles.selectOptions}>
			<GenericModal
				closeBtnDisabled
				isOpen={isOpenGenericModal}
				setIsOpen={setIsOpenGenericModal}
				onClick={() => handleNextPageModal()}
				content={{
					modalContent: (<ModalContentSelectOptions corrects={selectOptions.corrects} options={userOptions}/>),
				}}
			/>

			<div className={styles.gameBody}>
				<span>
					Marque somente as respostas que são CONDUTAS ESPERADAS
				</span>
				<div className={styles.optionsWrapper}>
					{currentOptions.map((option) => (
						<div className={styles.option} key={option.id}>
							<label className={styles.customCheckbox}>
								<input
									type="checkbox"
									id={`checkbox-${option.id}`}
									name={option.text}
									checked={userOptions.includes(option)}
									onChange={() =>
										handleCheckboxChange(option)
									}
								/>
								<span className={styles.checkmark}></span>
							</label>
							<span>{option.text}</span>
						</div>
					))}
				</div>
				<div className={styles.btnWrapper}>
					<button
						className={`${styles.red} ${
							currentPage === 0 && styles.disabled
						}`}
						onClick={handlePreviousPage}
					>
						Voltar
					</button>
					<button onClick={handleNextPage}>{btnText}</button>
				</div>
			</div>
		</div>
	);
}
