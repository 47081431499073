/* eslint-disable jsx-a11y/alt-text */
import styles from './shieldComponent.module.scss';

const files = [
	{
		id: 1,
		file: '/shield_images/13.png',
		className: styles.shieldPart13,
        missionId: 1,
	},
	{
		id: 6,
		file: '/shield_images/14.png',
		className: styles.shieldPart14,
        missionId: 1,
	},
	{
		id: 12,
		file: '/shield_images/1.png',
		className: styles.shieldPart1,
        missionId: 1,
	},
	{
		id: 14,
		file: '/shield_images/2.png',
		className: styles.shieldPart2,
        missionId: 1,
	},
	{
		id: 19,
		file: '/shield_images/3.png',
		className: styles.shieldPart3,
        missionId: 1,
	},
	{
		id: 21,
		file: '/shield_images/4.png',
		className: styles.shieldPart4,
        missionId: 1,
	},
	{
		id: 26,
		file: '/shield_images/5.png',
		className: styles.shieldPart5,
        missionId: 1,

	},
	{
		id: 28,
		file: '/shield_images/6.png',
		className: styles.shieldPart6,
        missionId: 1,
	},
	{
		id: 33,
		file: '/shield_images/7.png',
		className: styles.shieldPart7,
        missionId: 1,

	},
	{
		id: 35,
		file: '/shield_images/8.png',
		className: styles.shieldPart8,
        missionId: 1,
	},
	{
		id: 40,
		file: '/shield_images/9.png',
		className: styles.shieldPart9,
        missionId: 1,
	},
	{
		id: 42,
		file: '/shield_images/10.png',
		className: styles.shieldPart10,
        missionId: 1,
	},
	{
		id: 47,
		file: '/shield_images/11.png',
		className: styles.shieldPart11,
        missionId: 1,
	},
	{
		id: 232,
		file: '/shield_images/12.png',
		className: styles.shieldPart12,
        missionId: 1,
 	},

];

export default function ShieldComponent({ missions = [], className }) {
    return (
        <div className={styles.shieldWrapper}>
            <div className={`${styles.shield} ${className}`}>
                {files.map((img) => {
                    if (missions.includes(img.id)) {
                        return (
                            <img
                                src={img.file}
                                key={`shield_part_${img.id}`}
                                className={`${styles.positionAbsolute} ${img.className}`}
                            />
                        );
                    }
                    return null;
                })}
                <div className={styles.circle}></div>
            </div>
        </div>
    );
}
