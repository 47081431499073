import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useQuery } from 'src/hooks/queryClient';
import styles from './reportPlayers.module.scss';
import * as XLSX from 'xlsx';
export const adminList = [
	'ce1cd048-11f3-4458-9be9-4cf6da677444', // vitor
	'7b42419e-0bfc-45ed-abac-c8a3024f141d', // leo
	'aaa8326a-f1cc-4862-b8b8-cf5520a2e059', // jeff
	'a39f427e-8f6d-4762-afd4-cb0bd831a631', //thais
	'dcc5646b-68b5-46aa-bbb0-fae5bfcfbc15', // eliseu sicredi
	'7c644bb9-a630-487a-9a8c-fad2ca909bfb', // poliana sicredi
	'571be811-ce19-4e7f-b837-1a8a15b6fcd2', // jamilli sicredi
];

export default function Reports({ user }) {
	const [week, setWeek] = useState('0');
	const [selectedOrganization, setSelectedOrganization] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [atualizacao, setAtualizacao] = useState("");

	const exportToExcel = () => {
		const data = filterValidPlayers().map((player) => {
			if (player.organization === 'chave-mestra') return {};
	
			// Criação do objeto básico
			const playerData = {
				Name: player.name,
				Email: player.email,
				Cooperativa: player.organization,
				'Pontuação por cadeado': player.escapeLive?.lockScore || 0,
				'Pontuação por tempo': player.escapeLive?.timeScore || 0,
				'Tomada de decisão': player.quiz?.quizScore || 0,
				'Condutas esperadas': player.quiz?.scoreSelectedOptions || 0,
				'Total Player Score':
					(player.escapeLive?.lockScore || 0) +
					(player.escapeLive?.timeScore || 0) +
					(player.quiz?.quizScore || 0) +
					(player.quiz?.scoreSelectedOptions || 0)
			};
	
			// Adiciona a coluna 'Semana finalizada' somente se week for diferente de '0'
			if (week !== '0') {
				playerData['Semana finalizada'] = player.endedWeek ? 'Sim' : 'Não';
			}else{
				playerData['Semana 1 Finalizada '] = player.endedWeeks.week1 ? 'Sim' : 'Não';
				playerData['Semana 2 Finalizada '] = player.endedWeeks.week2 ? 'Sim' : 'Não';
				playerData['Semana 3 Finalizada '] = player.endedWeeks.week3 ? 'Sim' : 'Não';
				playerData['Semana 4 Finalizada '] = player.endedWeeks.week4 ? 'Sim' : 'Não';
				playerData['Semana 5 Finalizada '] = player.endedWeeks.week5 ? 'Sim' : 'Não';
				playerData['Semana 6 Finalizada '] = player.endedWeeks.week6 ? 'Sim' : 'Não';
				playerData['Semana 7 Finalizada '] = player.endedWeeks.week7 ? 'Sim' : 'Não';
				playerData['Semana 8 Finalizada '] = player.endedWeeks.week8 ? 'Sim' : 'Não';
				playerData['Semana 9 Finalizada '] = player.endedWeeks.week9 ? 'Sim' : 'Não';
				playerData['Semana 10 Finalizada '] = player.endedWeeks.week10 ? 'Sim' : 'Não';
				playerData['Semana 11 Finalizada '] = player.endedWeeks.week11 ? 'Sim' : 'Não';
				playerData['Semana 12 Finalizada '] = player.endedWeeks.week12 ? 'Sim' : 'Não';
				playerData['Semana 13 Finalizada '] = player.endedWeeks.week13 ? 'Sim' : 'Não';
				
			}
	
			return playerData;
		});
	
		const worksheet = XLSX.utils.json_to_sheet(data);
	
		// Definindo largura das colunas dinamicamente
		const wscols = [
			{ wch: 40 }, // Largura para 'Name'
			{ wch: 40 }, // Largura para 'Email'
			{ wch: 30 }, // Largura para 'Cooperativa'
			{ wch: 20 }, // Largura para 'Pontuação por cadeado'
			{ wch: 20 }, // Largura para 'Pontuação por tempo'
			{ wch: 20 }, // Largura para 'Tomada de decisão'
			{ wch: 20 }, // Largura para 'Condutas esperadas'
			{ wch: 20 }, // Largura para 'Total Player Score'
		];
	
		// Adiciona a largura da coluna 'Semana finalizada' somente se week for diferente de '0'
		if (week !== '0') {
			wscols.push({ wch: 20 });
		}
	
		worksheet['!cols'] = wscols;
	
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
	
		XLSX.writeFile(workbook, 'Relatório de Jogadores.xlsx');
	};
	

	useEffect(() => {
		if (!adminList.includes(user.id)) {
			window.location.href = `/${user.id}`;
		}
	}, [user]);

	const token = localStorage.getItem('SUT');

	// const { data: report, status } = useQuery({
	// 	url: `/users/getReportAllPlayers/${week}`,
	// 	key: 'reportPlayers',
	// 	enabled: !!token,
	// 	token: token,
	// });
	const { data: report, status } = useQuery({
		url: `/getReportV2/${week}`,
		key: 'reportPlayers',
		enabled: !!token,
		token: token,
	});

	const filterValidPlayers = () => {
		if (!report || report.length === 0) return [];
		return report
			.filter((player) => {
				if (player.organization === 'chave-mestra') return false; // Exclui jogadores da organização 'chave-mestra'
				const organizationMatch =
					selectedOrganization === '' ||
					player.organization === selectedOrganization;
				const emailMatch = player.email
					.toLowerCase()
					.includes(searchTerm.toLowerCase());
				return organizationMatch && emailMatch;
			})
			.sort((a, b) => a.name.localeCompare(b.name));
	};

	const countValidPlayers = () => {
		// Agora usando o filtro atualizado
		return filterValidPlayers().filter(
			(player) => player.endedWeek === true
		).length;
	};

	const calculateAverageScore = (scoreType) => {
		const validPlayers = filterValidPlayers();
		if (validPlayers.length === 0) return 0;
		const total = validPlayers.reduce((sum, player) => {
			return sum + (scoreType(player) || 0);
		}, 0);
		return (total / validPlayers.length).toFixed(2);
	};
	const calculateAverageTotalScore = () => {
		const validPlayers = filterValidPlayers();

		if (week === '0') {
			const total = validPlayers.reduce((sum, player) => {
				return (
					sum +
					(player.escapeLive?.lockScore || 0) +
					(player.escapeLive?.timeScore || 0) +
					(player.quiz?.quizScore || 0) +
					(player.quiz?.scoreSelectedOptions || 0)
				);
			}, 0);
			return (total / validPlayers.length).toFixed(2);
		} else if (week % 2 === 0) {
			const total = validPlayers.reduce((sum, player) => {
				return sum + (player.quiz?.scoreSelectedOptions || 0);
			}, 0);
			return (total / validPlayers.length).toFixed(2);
		} else {
			const total = validPlayers.reduce((sum, player) => {
				return (
					sum +
					(player.escapeLive?.lockScore || 0) +
					(player.escapeLive?.timeScore || 0) +
					(player.quiz?.quizScore || 0)
				);
			}, 0);
			return (total / validPlayers.length).toFixed(2);
		}
	};

	const calculateAverageLockScore = () => {
		return calculateAverageScore((player) => player.escapeLive?.lockScore);
	};

	const calculateAverageTimeScore = () => {
		return calculateAverageScore((player) => player.escapeLive?.timeScore);
	};

	const calculateAverageQuizScore = () => {
		return calculateAverageScore((player) => player.quiz?.quizScore);
	};

	const calculateAverageScoreSelectedOptions = () => {
		return calculateAverageScore(
			(player) => player.quiz?.scoreSelectedOptions
		);
	};

	const countPlayersWithExactLockScore = () => {
		if (!report || report.length === 0) return 0;
		return report.filter((player) => player.escapeLive?.endedEscape)
			.length;
	};

	const handleOrganizationChange = (e) => {
		setSelectedOrganization(e.target.value);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const reportData = [
		{
			text: 'Usuários cadastrados',
			data: report?.length || 0,
			show: true, // Sempre visível
		},
		{
			text: `Usuários com Semana ${week} finalizada`,
			data: `${countValidPlayers()} / ${report?.length || 0}`,
			show: week.toString() !== '0', // Não aparece quando week é 'total'
		},
		{
			text: 'Jogadores que concluiram os 2 escapes',
			data: `${countPlayersWithExactLockScore()} / ${
				report?.length || 0
			}`,
			show: week % 2 !== 0, // Aparece apenas em semanas ímpares
		},
		{
			text: 'Média da Pontuação Total',
			data: `${calculateAverageTotalScore()} / ${
				week === '0' ? '3000' : week % 2 === 0 ? '300' : '2400'
			}`,
			show: true, // Sempre visível
		},
		{
			text: 'Média Pontuação por cadeados',
			data: `${calculateAverageLockScore()} / ${
				week % 2 === 0 ? '0' : '750'
			}`,
			show: week % 2 !== 0, // Aparece apenas em semanas ímpares
		},
		{
			text: 'Média Pontuação por Tempo',
			data: `${calculateAverageTimeScore()} / ${
				week % 2 === 0 ? '0' : '1050'
			}`,
			show: week % 2 !== 0, // Aparece apenas em semanas ímpares
		},
		{
			text: 'Média Pontuação da Tomada de Decisão',
			data: `${calculateAverageQuizScore()} / ${
				week % 2 === 0 ? '0' : '600'
			}`,
			show: week % 2 !== 0, // Aparece apenas em semanas ímpares
		},
		{
			text: 'Média Pontuação de Condutas esperadas',
			data: `${calculateAverageScoreSelectedOptions()} / ${
				week % 2 === 0 ? '300' : '0'
			}`,
			show: week !== '0' && week % 2 === 0, // Aparece apenas em semanas pares
		},
	];

	const shouldShowReportItem = (item) => {
		return item.show;
	};

	const formatName = (name) => {
		return name
			.toLowerCase()
			.replace(/\b\w/g, (char) => char.toUpperCase());
	};

	const formatEmail = (email) => {
		return email.toLowerCase();
	};
 
	useEffect(() => {
		setAtualizacao(`Atualizado em: 14/11/2024 ás 12:25`);
	  }, []);
	  
	return (
		<div className={styles.report}>
			{status === 'success' ? (
				<>
					<div className={styles.avarage}>
						<h1>Relatório</h1>
						<span>{atualizacao}</span>
						<div className={styles.headerItems}>
							<div className={styles.summary}>
								<h3>Resumo</h3> 
								<div className={styles.header}>
									{reportData
										.filter((report) =>
											shouldShowReportItem(report)
										)
										.map((report, i) => (
											<div
												className={`${
													styles.reportItem
												} ${
													i % 2 === 0
														? styles.green
														: styles.green2
												}`}
												key={`report_${i}`}
											>
												<span>{report.text} :</span>
												<h3>{report.data}</h3>
											</div>
										))}
								</div>
							</div>
							<div className={styles.filter}>
								<div className={styles.select}>
									<h4>Cooperativa</h4>
									<select
										value={selectedOrganization}
										onChange={handleOrganizationChange}
									>
										<option value="0">Todos</option>
										<option value="central">
											Central Sicredi Brasil Central
										</option>
										<option value="pantanal">
											Sicredi Pantanal MS
										</option>
										<option value="centro_sul">
											Sicredi Centro-Sul MS/BA
										</option>
										<option value="uniao_ms_to">
											Sicredi União MS/TO
										</option>
										<option value="campo_grande">
											Sicredi Campo Grande MS
										</option>
										<option value="celeiro">
											Sicredi Celeiro Centro Oeste
										</option>
										<option value="cerrado">
											Sicredi Cerrado GO
										</option>
										<option value="planalto">
											Sicredi Planalto Central
										</option>
									</select>
								</div>
								<div className={styles.select}>
									<h4>Semana</h4>
									<select
										value={week}
										onChange={(e) =>
											setWeek(e.target.value)
										}
									>
										<option value="0">Total</option>
										<option value="1">Semana 1</option>
										<option value="2">Semana 2</option>
										<option value="3">Semana 3</option>
										<option value="4">Semana 4</option>
										<option value="5">Semana 5</option>
										<option value="6">Semana 6</option>
										<option value="7">Semana 7</option>
										<option value="8">Semana 8</option>
										<option value="9">Semana 9</option>
										<option value="10">Semana 10</option>
										<option value="11">Semana 11</option>
										<option value="12">Semana 12</option>
										<option value="13">Semana 13</option>
									</select>
								</div>
								<div className={styles.select}>
									<h4>Buscar por Email</h4>
									<input
										type="text"
										value={searchTerm}
										onChange={handleSearchChange}
										placeholder="Digite o email do jogador"
									/>
								</div>
							</div>
							<div>
								<button
									className={styles.btn}
									onClick={exportToExcel}
								>
									Exportar para Excel
								</button>
							</div>
						</div>
					</div>
					<div className={styles.tableContainer}>
						<table className={styles.table}>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th>Cooperativa</th>
									<th>Pontuação por cadeado</th>
									<th>Pontuação por tempo</th>
									<th>Tomada de decisão</th>
									<th>Condutas esperadas</th>
									<th>Total Player Score</th>
									{week !== '0' && (
										<th>Semana {week} finalizada</th>
									)}
								</tr>
							</thead>
							<tbody>
								{filterValidPlayers().map((player, index) => {
									const playerTotalScore =
										(player.escapeLive?.lockScore || 0) +
										(player.escapeLive?.timeScore || 0) +
										(player.quiz?.quizScore || 0) +
										(player.quiz?.scoreSelectedOptions ||
											0);

									return (
										<tr
											className={`${styles.tr} ${
												index % 2 === 0
													? styles.green
													: styles.green2
											}`}
											key={index}
										>
											<td>{formatName(player.name)}</td>
											<td>{formatEmail(player.email)}</td>
											<td>{player.organization}</td>
											<td>
												{(
													player.escapeLive
														?.lockScore || 0
												).toFixed(2)}
											</td>
											<td>
												{(
													player.escapeLive
														?.timeScore || 0
												).toFixed(2)}
											</td>
											<td>
												{(
													player.quiz?.quizScore || 0
												).toFixed(2)}
											</td>
											<td>
												{(
													player.quiz
														?.scoreSelectedOptions ||
													0
												).toFixed(2)}
											</td>
											<td>
												{playerTotalScore.toFixed(2)}
											</td>
											{week !== '0' && (
												<td>
													{player.endedWeek
														? 'Sim'
														: 'Não'}
												</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<div className={styles.loading}>
					<ReactLoading
						type={'spin'}
						color={'green'}
						height={'5%'}
						width={'5%'}
					/>
				</div>
			)}
		</div>
	);
}
